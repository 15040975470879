<template>
  <FormModal :title="$t('title')" show-back-btn>
    <template #progressbar>
      <div class="flex justify-center py-8">
        <div class="flex space-x-3">
          <span class="rounded block h-1 bg-primary w-12" />
          <span class="rounded block h-1 bg-primary w-12" />
          <span class="rounded block h-1 bg-lightgrey w-12" />
          <span class="rounded block h-1 bg-lightgrey w-12" />
        </div>
      </div>
    </template>

    <template #body>
      <DwellingTypeFormStep ref="step" :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/jupiter/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import DwellingTypeFormStep from '~/components/form/steps/dwellingType/DwellingTypeFormStep'

export default {
  components: {
    DwellingTypeFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Wat voor type woning is het?',
      path: '/offertes-aanvragen/type-woning',
      progressValue: 40,
      checkoutStep: 3,
    }
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Wat voor type woning is het?"
  },
  "en-GB": {
    "title": "What type of dwelling is it?"
  }
}
</i18n>
