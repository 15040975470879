<template>
  <FormModal :title="$t('title')" show-back-btn>
    <template #progressbar>
      <div class="flex justify-center py-8">
        <div class="flex space-x-3">
          <span class="rounded block h-1 bg-primary w-12" />
          <span class="rounded block h-1 bg-primary w-12" />
          <span class="rounded block h-1 bg-primary w-12" />
          <span class="rounded block h-1 bg-lightgrey w-12" />
        </div>
      </div>
    </template>

    <template #body>
      <DwellingConditionFormStep ref="step" :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/jupiter/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import DwellingConditionFormStep from '~/components/form/steps/dwellingCondition/DwellingConditionFormStep'

export default {
  components: {
    DwellingConditionFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Wat is de staat van de woning?',
      path: '/offertes-aanvragen/staat-van-woning',
      progressValue: 60,
      checkoutStep: 4,
    }
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Wat is de staat van de woning?"
  },
  "en-GB": {
    "title": "What is the condition of the property?"
  }
}
</i18n>
