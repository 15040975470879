<template>
  <DwellingConditionRadioField :choices="choices" v-on="$listeners" />
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import DwellingConditionRadioField from '~/components/form/fields/dwellingCondition/DwellingConditionRadioField'

export default {
  name: 'DwellingConditionRadioFormPart',

  components: {
    DwellingConditionRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(
          this.$i18n.t('excellent'),
          this.$i18n.t('excellent'),
          this.$i18n.t('excellent'),
          {
            description: this.$i18n.t('excellentDescription'),
          },
        ),
        new Selectable(
          this.$i18n.t('good'),
          this.$i18n.t('good'),
          this.$i18n.t('good'),
          {
            description: this.$i18n.t('goodDescription'),
          },
        ),
        new Selectable(
          this.$i18n.t('mediocre'),
          this.$i18n.t('mediocre'),
          this.$i18n.t('mediocre'),
          {
            description: this.$i18n.t('mediocreDescription'),
          },
        ),
      ],
    }
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "excellent": "In uitstekende staat",
    "excellentDescription": "De woning is perfect in orde, zo goed als nieuw.",
    "good": "In goede staat",
    "goodDescription": "Goed onderhouden, maar de woning is niet in nieuwstaat.",
    "mediocre": "In matige staat",
    "mediocreDescription": "Er is op korte termijn groot onderhoud nodig aan de woning."
  },
  "en-GB": {
    "excellent": "In excellent condition",
    "excellentDescription": "The house is in perfect condition, almost new.",
    "good": "In good condition",
    "goodDescription": "Well maintained, but the house is not in new condition.",
    "mediocre": "In mediocre condition",
    "mediocreDescription": "The house needs major maintenance in the short term."
  }
}
</i18n>
