<template>
  <DwellingTypeRadioField :choices="choices" v-on="$listeners" />
</template>

<script>
import { Selectable } from 'chimera/all/components/models/Selectable'
import DwellingTypeRadioField from '~/components/form/fields/dwellingType/DwellingTypeRadioField'

export default {
  name: 'DwellingTypeRadioFormPart',

  components: {
    DwellingTypeRadioField,
  },

  /**
   * @returns {object}
   */
  data() {
    return {
      choices: [
        new Selectable(
          this.$i18n.t('apartment'),
          this.$i18n.t('apartment'),
          this.$i18n.t('apartment'),
        ),
        new Selectable(
          this.$i18n.t('townhouse'),
          this.$i18n.t('townhouse'),
          this.$i18n.t('townhouse'),
        ),
        new Selectable(
          this.$i18n.t('corner'),
          this.$i18n.t('corner'),
          this.$i18n.t('corner'),
        ),
        new Selectable(
          this.$i18n.t('semiDetached'),
          this.$i18n.t('semiDetached'),
          this.$i18n.t('semiDetached'),
        ),
        new Selectable(
          this.$i18n.t('attached'),
          this.$i18n.t('attached'),
          this.$i18n.t('attached'),
        ),
        new Selectable(
          this.$i18n.t('detached'),
          this.$i18n.t('detached'),
          this.$i18n.t('detached'),
        ),
        new Selectable(
          this.$i18n.t('unknown'),
          this.$i18n.t('unknown'),
          this.$i18n.t('unknown'),
        ),
      ],
    }
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "apartment": "Appartement",
    "townhouse": "Tussenwoning",
    "corner": "Hoekwoning",
    "semiDetached": "2-onder-1-kapwoning",
    "attached": "Geschakelde woning",
    "detached": "Vrijstaande woning",
    "unknown": "Weet ik niet"
  },
  "en-GB": {
    "apartment": "Apartment",
    "townhouse": "Townhouse",
    "corner": "Corner house",
    "semiDetached": "Semi-detached house",
    "attached": "Attached house",
    "detached": "Detached house",
    "unknown": "I don't know"
  }
}
</i18n>
